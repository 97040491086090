<template>
  <div class="page">
    <v-card>
      <v-card-title>
        <span class="h2">{{ Title }}</span>
        <v-spacer />
        <Btn flat color="primary" icon="backspace" @click="back">
          戻る
        </Btn>
        <Btn
          class="ml-3"
          color="info"
          icon="details"
          :disabled="edit"
          @click="
            edit = true;
            add = false;
          "
        >
          編集
        </Btn>
      </v-card-title>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            検索条件
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form
              v-model="valid"
              ref="form"
              :disabled="!edit"
              @submit.prevent="submit"
            >
              <v-text-field
                v-model="selectDenpyo"
                outlined
                label="伝票番号"
                readonly
                dense
              />
              <DatePicker
                v-model="selectDate"
                label="伝票日付"
                prepend-inner-icon="mdi-calendar"
                type="day"
                outlined
                :disabled="canadd"
                :rules="[selected]"
              />
              <v-autocomplete
                v-model="selectTokui"
                @change="changeTokui(selectTokui)"
                :items="tokuiList"
                outlined
                label="得意先"
                :item-text="item => `${item.refcode}：${item.name}`"
                item-value="code"
                chips
                :readonly="canadd"
                :rules="[selected]"
                return-object
              >
              </v-autocomplete>
              <v-autocomplete
                v-model="selectFormat"
                :items="formatList"
                outlined
                label="フォーマット"
                :item-text="item => `${item.code}：${item.name}`"
                item-value="code"
                chips
                @change="changeSelectFormat(selectFormat)"
                :disabled="!edit"
                return-object
              >
              </v-autocomplete>
              <v-text-field
                v-model="inputTekiyou"
                placeholder="ここに文字を入力"
                prepend-inner-icon="mdi-text"
                label="摘要"
                outlined
                :disabled="!edit"
              />
              <v-autocomplete
                v-model="selectKaiuke"
                :items="kaiukeList"
                outlined
                label="買受人"
                item-text="ukeno"
                item-value="id"
                chips
                v-if="tokuiBunrui"
                :disabled="!edit"
                clearable
                :rules="[selected]"
              >
              </v-autocomplete>
              <v-autocomplete
                v-model="selectTokuiupd"
                :items="tokuiListupd"
                v-if="canadd && tokuiGroupexist"
                outlined
                label="更新対象"
                :item-text="item => `${item.refcode}：${item.name}`"
                item-value="code"
                chips
                multiple
                return-object
                clearable
                deletable-chips
              ></v-autocomplete>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <template v-if="tabTokuiList.length > 0">
        <v-card-actions>
          <v-autocomplete
            v-model="selectTanto"
            :items="tableForm.tanto"
            outlined
            label="担当者"
            :item-text="item => `${item.dispTanNm}：${item.tanNo}`"
            item-value="id"
            chips
            :disabled="!edit"
            clearable
            dense
            v-if="false"
          >
          </v-autocomplete>
          <v-row>
            <v-col cols="4">
              <Btn
                icon="plus"
                color="info"
                @click="rowAdd('')"
                style="margin-left: 10px"
                :disabled="!edit"
                >行追加</Btn
              >
            </v-col>
            <v-col cols="4">
              <v-banner v-if="errorCount !== 0" single-line shaped slot="icon">
                <!--v-icon
                  
                >
                  mdi-exclamation
                </v-icon-->
                <!-- {{ errorCount }}件の入力内容は修正が必要です。 -->
              </v-banner>
            </v-col>
            <v-col cols="4">
              <v-btn
                type="login"
                color="success"
                :disabled="!edit"
                @click="submit"
                style="margin-left: auto"
              >
                完了
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-tabs
          v-model="tabIndex"
          background-color="deep-purple accent-4"
          center-active
          dark
        >
          <v-tab
            v-for="(value, index) in tabTokuiList"
            :key="index"
            :value="index"
          >
            {{ value.name }}
          </v-tab>
        </v-tabs>
        <JyutyuFormatTable
          :tableData="tableData"
          @setdata="tableData = $event"
          @errorCount="updateErrorCount"
        >
        </JyutyuFormatTable>
      </template>
    </v-card>
  </div>
</template>
<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Forms from "@/mixins/forms";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";
//components
import JyutyuFormatTable from "@/components/JyutyuFormatTable.vue";

import Api from "@/mixins/api";
import Vue from "vue";
import moment from "moment";

const DATE = moment()
  .add(1, "d")
  .format("YYYY-MM-DD");

const reg = new RegExp(/^[0-9]+$/);
const baseRow = {
  denpyoNo: null,
  santiId: null,
  shohinId: null,
  edano: null,
  shohinNm: "",
  kikakuId: null,
  kikakuNm: "",
  irisuu: "",
  hattyusuu: "",
  taniId: null,
  taniNm: "",
  jissuu: "",
  tanka: "",
  bikou: "",
  tantoId: null,
  tantoNm: ""
};
export default {
  name: "JyutyuInput",
  mixins: [Common, Forms, ShowDialogs, Api],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  components: {
    JyutyuFormatTable
  },
  data: () => ({
    request: {},
    params: null,
    add: false,
    edit: false,
    denpyoDate: "",
    tokuiList: [],
    tokuiListupd: [],
    errorCount: 0,
    selectTokui: {
      code: "",
      name: "",
      refcode: "",
      option1: "",
      option2: "",
      option3: ""
    },
    selectTokuiupd: [],
    kaiukeList: [],
    allTableData: [
      [
        {
          denpyoNo: "",
          tokuiId: "",
          gyo: 1,
          santiId: "",
          shohinId: "",
          edano: "",
          shohinNm: "",
          kikakuId: "",
          irisuu: "",
          hattyusuu: "",
          taniId: "",
          jissuu: "",
          tanka: "",
          bikou: "",
          tantoId: ""
        }
      ]
    ],
    formatList: [],
    tabTokuiList: [],
    dispFormatIndexOld: 0,
    selectDenpyo: "",
    selectDate: DATE,
    selectTokuiGroup: "",
    selectFormat: {
      code: "",
      name: "",
      refcode: "",
      option1: "",
      option2: "",
      option3: ""
    },
    selectTanto: "",
    selectKaiuke: "",
    // インプット（ダミー）
    inputTekiyou: "",
    tabIndex: 0,
    tableData: [baseRow],

    // 入力規則
    selected: value => !!value || "選択してください", // 選択必須の制約
    required: value => !!value || "入力してください", // 入力必須の制約
    numeric: value => reg.test(value) || "数字を入力してください"
  }),
  computed: {
    Title() {
      return (
        this.params.title +
        " " +
        (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    },
    canadd() {
      if (this.add === true) {
        return false;
      } else {
        return true;
      }
    },
    tokuiBunrui() {
      if (this.selectTokui.code === undefined) {
        return false;
      } else {
        return this.selectTokui.option3 === "1";
      }
    },
    tokuiGroupexist() {
      if (this.selectTokui.code === undefined) {
        if (this.selectTokui === undefined) {
          return false;
        } else {
          const code = this.selectTokui.split("-");
          return code[0] === "G";
        }
      } else {
        const code = this.selectTokui.code.split("-");
        return code[0] === "G";
      }
    }
  },
  watch: {
    tabIndex: function(newVal, oldVal) {
      this.tableData = this.allTableData[newVal];
    },
    tableData: function(newVal, oldVal) {
      this.allTableData[this.tabIndex] = newVal;
    }
  },
  methods: {
    async submit() {
      this.$loading();
      try {
        // const validate = await this.checkAllTableData();
        // if (!validate) return;

        //明細リスト
        const updMeisaiData = [];
        //ヘッダー情報
        const denpyoDate = this.selectDate;
        const biko = this.inputTekiyou;
        const kaiuke = this.selectKaiuke;
        const formatId =
          this.selectFormat.code === undefined
            ? this.selectFormat
            : this.selectFormat.code;

        let tokui = 0;
        let group = 0;
        let index = 1;
        let denpyo = this.selectDenpyo;

        // 更新前にテーブル情報を整理
        const tableData = this.tableData;

        const code =
          this.selectTokui.code === undefined
            ? this.selectTokui.split("-")
            : this.selectTokui.code.split("-");
        const codeName = code[0];
        const codeId = Number(code[1]);

        console.log(this.selectTokuiupd, "this.selectTokuiupd");
        if (!this.add && codeName === "G" && this.selectTokuiupd.length === 0) {
          this.$error("得意先がグループの場合は、更新対象を選択してください。");
          return;
        }

        if (codeName === "T") {
          tokui = codeId;
          let gyo = 1;
          //1行ごとに明細データを生成
          for (const rowIndex in tableData) {
            const row = Vue.util.extend({}, tableData[rowIndex]);
            row.denpyoNo = denpyo;
            row.gyo = gyo;
            row.tokuiId = codeId;
            //初期値の場合はnull
            if (!row.irisuu) row.irisuu = null;
            if (!row.hattyusuu) row.hattyusuu = null;
            if (!row.jissuu) row.jissuu = null;
            if (!row.tanka) row.tanka = null;
            updMeisaiData.push(row);
            gyo++;
          }
        } else if (codeName === "G") {
          group = codeId;
          index = this.allTableData.length;
          console.log("allTableData", this.allTableData);
          for (let i = 0; i < this.tabTokuiList.length; i++) {
            const editTable = this.allTableData[i];
            console.log("tabtokui", this.tabTokuiList[i]);
            const tokuiId = this.tabTokuiList[i].code;
            let gyo = 1;
            if (i > 0) {
              denpyo = denpyo + 1;
            }
            for (const rowIndex in editTable) {
              const row = Vue.util.extend({}, editTable[rowIndex]);
              // ・フォーマットが選択されている場合は、フォーマットIDを設定
              // ・得意先ID、行番号を設定
              row.denpyoNo = denpyo;
              row.tokuiId = tokuiId;
              row.gyo = gyo;
              //初期値の場合はnull
              if (!row.irisuu) row.irisuu = null;
              if (!row.hattyusuu) row.hattyusuu = null;
              if (!row.jissuu) row.jissuu = null;
              if (!row.tanka) row.tanka = null;
              updMeisaiData.push(row);
              gyo++;
            }
          }
        }
        const updateList = [];
        if (this.selectTokuiupd.length == 0) {
          updateList.push("-1");
          updateList.push(String(tokui));
        } else {
          this.selectTokuiupd.forEach(record => {
            updateList.push(String(record.code));
          });
        }
        console.log(updateList, "updateList");
        const param = {
          jyutyuHedder: {
            denpyoNo: this.selectDenpyo,
            denpyoDate: new Date(denpyoDate),
            tokuiId: tokui,
            tokuiGroup: group,
            biko: biko,
            indexId: index,
            kaiukeId: kaiuke,
            formatId: formatId
          },
          jyutyuMeisaiList: updMeisaiData,
          updatelst: updateList
        };
        console.log("submit_param", param);
        if (this.add) {
          await this.$post(this.Paths.jyutyuInput, param);
        } else {
          await this.$put(this.Paths.jyutyuInput, param);
        }
        this.$info("更新しました。", "受発注入力");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async checkAllTableData() {
      const allTable = this.allTableData;
      const result = [];
      const text = [];
      if (this.$store.state.authority === "1") return true;
      for (const t of allTable) {
        result.push(
          t.some(e => !e.tantoId || e.shohinId === 0 || (!e.tanka && e.jissuu))
        );
      }
      for (const [i, v] of result.entries()) {
        if (v === true) {
          console.log(this.tabTokuiList[i].name);
          text.push(this.tabTokuiList[i].name + "\n");
        }
      }
      if (text.length === 0) return true;
      text.push(
        "の入力内容は修正が必要です。\n登録は可能ですが、データの出力ができないので注意してください。"
      );
      return await this.$confirm(text.join(""));
    },
    // ==============================
    // 行操作
    // ==============================
    rowIns(index) {
      console.log("rowIns", "開始");
      const base = Object.assign({}, baseRow);
      const tanto = this.selectTanto;
      const tableData = this.tableData;
      //テーブルに明細番号を挿入する
      //担当者が選択されているとき
      if (tanto) base.tantoId = tanto;
      this.tableData = [
        ...tableData.slice(0, index),
        ...[base],
        ...tableData.slice(index)
      ];
    },
    rowDel(index) {
      console.log("rowDel", "開始");
      this.tableData.splice(index, 1);
    },
    rowAdd() {
      console.log("rowAdd", "開始");
      const base = Object.assign({}, baseRow);
      const tanto = this.selectTanto;
      //テーブルに明細番号を挿入する
      //担当者が選択されているとき
      if (tanto) base.tantoId = tanto;
      this.tableData.push(base);
      this.$nextTick(function() {
        const el = this.$refs.table;
        if (el) {
          el.scrollTo(0, el.scrollHeight);
        }
      });
    },
    // ==============================
    // 得意先別に受発注フォーマット明細を取得
    // ==============================
    async getJyutyuRequest() {
      const result = await this.$get("/jyutyuInput/jyutyuRequest");
      return result;
    },
    async getJyutyuFormatMeisai(id, groupId, tokuiId) {
      console.log("getJyutyuMeisai");
      const param = {
        formatId: id,
        tokuiGroup: groupId,
        tokuiId: tokuiId
      };
      const encode = encodeURI(JSON.stringify(param));
      const result = await this.$get(
        "/jyutyuInput/format/meisai",
        "query=" + encode
      );
      return result;
    },
    async getJyutyuTokuiPulldown() {
      console.log("getJyutyuTokuiPulldown");
      const result = await this.$get(this.Paths.jyutyuTokuiPulldown);
      return result;
    },
    async getJyutyuFormatPulldown(tokuiId, groupId) {
      console.log("getJyutyuFormatPulldown");
      const param = {
        tokuiGroup: groupId == 0 ? null : groupId,
        tokuiId: tokuiId
      };
      const encode = encodeURI(JSON.stringify(param));
      const result = await this.$get(
        "/jyutyu/jyutyu/pulldown",
        "query=" + encode
      );
      console.log("result", result);
      return result;
    },
    async getTokuiPulldown(tokuiId, groupId) {
      console.log("getTokuiPulldown");
      const param = {
        groupId: groupId,
        tokuiId: tokuiId
      };
      const encode = encodeURI(JSON.stringify(param));
      const result = await this.$get(
        "/tokuiGroup/table/pulldown",
        "query=" + encode
      );
      console.log("reuslt", result);
      return result;
    },
    async getJyutyuMeisai(id) {
      console.log("getJyutyuMeisai");
      const param = {
        denpyoNo: id
      };
      const encode = encodeURI(JSON.stringify(param));
      const result = await this.$get(
        "jyutyuInput/jyutyuMeisai",
        "query=" + encode
      );
      const tableData = [];
      console.log(result);
      return result;
    },
    async getDenpyoNo() {
      const result = await this.$get("jyutyuInput/denpyoNo");
      return result;
    },
    async formatConfirm() {
      return await this.$confirm("テーブルを更新します。\nよろしいですか？");
    },
    // ==============================
    // フォーマットプルダウン変更
    // ==============================
    async changeTokui(value) {
      //フォームが空の場合変更なし
      if (!value) return;

      console.log(value);
      const tokuiId = value.option1;
      const groupId = value.option2;

      const promiseList = [
        this.getTokuiPulldown(tokuiId, groupId),
        this.getJyutyuFormatPulldown(tokuiId, groupId),
        this.getTokuiPulldown(tokuiId, groupId)
      ];

      const [tokui, format, updlst] = await Promise.all(promiseList);

      console.log(Array.from(Array(tokui.length), () => Array(0)));
      this.allTableData = Array.from(Array(tokui.length), () => Array(0));
      this.tableData = this.allTableData[this.tabIndex];

      this.tabTokuiList = tokui;
      this.formatList = format;
      updlst.splice(0, 0, {
        code: "-1",
        name: "ヘッダ",
        refcode: "0",
        option1: "ヘッダ",
        option2: "",
        option3: ""
      });
      this.tokuiListupd = updlst;
    },
    async changeTokuiIdonly(tokuiId, groupId) {
      //フォームが空の場合変更なし
      //if (!tokuiId) return;

      console.log(tokuiId);
      const promiseList = [
        this.getTokuiPulldown(tokuiId, groupId),
        this.getJyutyuFormatPulldown(tokuiId, groupId),
        this.getTokuiPulldown(tokuiId, groupId)
      ];

      const [tokui, format, updlst] = await Promise.all(promiseList);

      console.log(Array(tokui.length));
      this.allTableData = Array.from(Array(tokui.length), () => Array(0));
      this.tableData = this.allTableData[this.tabIndex];

      this.tabTokuiList = tokui;
      this.formatList = format;
      updlst.splice(0, 0, {
        code: "-1",
        name: "ヘッダ",
        refcode: "0",
        option1: "ヘッダ",
        option2: "",
        option3: ""
      });
      this.tokuiListupd = updlst;
    },
    async changeSelectFormat(value) {
      //フォームが空の場合変更なし
      if (!value) return;

      //変更確認
      if (!(await this.formatConfirm())) {
        this.selectFormat = this.dispFormatIndexOld;
        return;
      }

      const kaiuke = value.option1;
      const formatId = value.code;
      const groupId = this.selectTokui.option2;
      const tokuiId = this.selectTokui.option1;

      this.selectKaiuke = kaiuke != undefined ? Number(kaiuke) : 0;

      //明細データ取得
      this.allTableData = await this.getJyutyuFormatMeisai(
        formatId,
        groupId,
        tokuiId
      );

      this.tableData = this.allTableData[this.tabIndex];
      this.dispFormatIndexOld = formatId;
    },
    back() {
      const path = "/list/jyutyuInput";
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    updateErrorCount(value) {
      this.errorCount = value;
    }
  },
  mounted() {
    console.log("mounted");
  },
  async created() {
    console.log(this.name, "created");
    //リストからデータ取得
    this.params = this.$route.params;
    const listData = this.params.args;
    console.log("filter", this.params.filter);

    this.$loading();
    try {
      if (this.params.prosess && typeof this.params.prosess === "string")
        this.params.prosess = this.params.prosess.toLowerCase();

      switch (this.params.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      this.tokuiList = await this.getJyutyuTokuiPulldown();
      const request = await this.getJyutyuRequest();
      this.kaiukeList = request.kaiuke;

      //追加と更新判定
      if (!this.add) {
        console.log("listData", listData);
        this.selectDenpyo = listData.denpyoNo;
        this.selectDate = listData.dispDate;
        this.inputTekiyou = listData.biko;

        if (listData.tokuiGroup !== 0) {
          this.selectTokui = "G-" + listData.tokuiGroup;
        } else if (listData.tokuiId !== 0) {
          this.selectTokui = "T-" + listData.tokuiId;
        }

        this.selectTokuiGroup = listData.tokuiGroup;

        //await this.changeTokui(this.selectTokui);
        await this.changeTokuiIdonly(listData.tokuiId, listData.tokuiGroup);
        console.log("formatList", this.formatList);
        this.selectFormat = String(listData.formatId);

        this.selectKaiuke =
          listData.kaiukeId != undefined ? Number(listData.kaiukeId) : 0;

        this.allTableData = [];
        for (let i = 0; i < listData.indexId; i++) {
          const meisai = await this.getJyutyuMeisai(listData.denpyoNo + i);
          this.allTableData.push(meisai);
        }
        console.log("allTableData", this.allTableData);
        this.tableData = this.allTableData[this.tabIndex];
      } else {
        //初期テーブルにIDを設定
        const id = await this.getDenpyoNo();
        const base = Object.assign({}, baseRow);
        base.denpyoNo = id;
        this.selectDenpyo = id;
        //this.tableData = [base];
        this.allTableData = [[base]];
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>
<style lang="scss" scoped></style>
