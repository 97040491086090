<template>
  <v-card class="" id="meisai_card">
    <div ref="table" class="v-data-table v-data-table--has-bottom theme--light">
      <div class="v-data-table__wrapper">
        <table class="table_sticky" style="border-color: grey;">
          <thead class="v-data-table-header">
            <tr>
              <th v-for="(cal, i) in headers" :key="i">
                {{ cal }}
              </th>
            </tr>
          </thead>
          <tbody ref="tbody">
            <template v-for="(rowData, j) in tableData">
              <tr :key="j">
                <!-- 産地 -->
                <td class="td-santi" :key="rowData.santiNm">
                  <input
                    type="text"
                    v-model="rowData.santiNm"
                    class="table_col_input_text"
                    @keydown.enter.exact="onFocus(j, 1)"
                    @keydown.right.exact="onFocus(j, 1)"
                    @keydown.left.exact="onFocus(j, 99)"
                  />
                </td>
                <!-- 品名 -->
                <td class="td-shohin">
                  <input
                    type="text"
                    v-model="rowData.shohinNm"
                    class="table_col_input_text"
                    ref="inputError"
                    @keydown.enter.exact="onFocus(j, 2)"
                    @keydown.right.exact="onFocus(j, 2)"
                    @keydown.left.exact="onFocus(j, 0)"
                  />
                </td>
                <!-- 規格 -->
                <td class="td-kikaku">
                  <input
                    type="text"
                    v-model="rowData.kikakuNm"
                    class="table_col_input_text"
                    @keydown.enter.exact="onFocus(j, 3)"
                    @keydown.right.exact="onFocus(j, 3)"
                    @keydown.left.exact="onFocus(j, 1)"
                  />
                </td>
                <!-- 入数/発注数 -->
                <td class="td-number">
                  <input
                    type="number"
                    v-model="rowData.irisuu"
                    class="table_col_input_number"
                    min="0"
                    step="1"
                    :disabled="authority === '1'"
                    @keydown.enter.exact="onFocus(j, 4)"
                    @keydown.right.exact="onFocus(j, 4)"
                    @keydown.left.exact="onFocus(j, 2)"
                  />
                </td>
                <td class="td-number">
                  <input
                    type="number"
                    v-model="rowData.hattyusuu"
                    class="table_col_input_number"
                    min="0"
                    step="1"
                    @keydown.enter.exact="onFocus(j, 5)"
                    @keydown.right.exact="onFocus(j, 5)"
                    @keydown.left.exact="onFocus(j, 3)"
                  />
                </td>
                <!-- 単位 -->
                <td class="td-tanni">
                  <input
                    type="text"
                    v-model="rowData.taniNm"
                    class="table_col_input_text"
                    :disabled="authority === '1'"
                    @keydown.enter.exact="onFocus(j, 6)"
                    @keydown.right.exact="onFocus(j, 6)"
                    @keydown.left.exact="onFocus(j, 4)"
                  />
                </td>
                <!-- 実数/単価/備考 -->
                <td class="td-number">
                  <input
                    type="number"
                    v-model.number="rowData.jissuu"
                    class="table_col_input_number"
                    min="0"
                    step="1"
                    :disabled="authority === '1'"
                    @keydown.enter.exact="onFocus(j, 7)"
                    @keydown.right.exact="onFocus(j, 7)"
                    @keydown.left.exact="onFocus(j, 5)"
                  />
                </td>
                <td class="td-number">
                  <input
                    type="number"
                    v-model.number="rowData.tanka"
                    class="table_col_input_number"
                    min="0"
                    step="1"
                    :disabled="authority === '1'"
                    :class="
                      authority !== '1' && !rowData.tanka && rowData.jissuu
                        ? 'inputError'
                        : ''
                    "
                    ref="inputError"
                    @keydown.enter.exact="onFocus(j, 8)"
                    @keydown.right.exact="onFocus(j, 8)"
                    @keydown.left.exact="onFocus(j, 6)"
                  />
                </td>
                <td class="td-text">
                  <input
                    type="text"
                    v-model="rowData.bikou"
                    class="table_col_input_text"
                    @keydown.enter.exact="onFocus(j, 9)"
                    @keydown.right.exact="onFocus(j, 9)"
                    @keydown.left.exact="onFocus(j, 7)"
                  />
                </td>
                <!-- 担当 -->
                <td class="td-tanto">
                  <input
                    type="text"
                    v-model="rowData.tantoNm"
                    class="table_col_input_text"
                    :disabled="authority === '1'"
                    @keydown.enter.exact="onFocus(j, 10)"
                    @keydown.right.exact="onFocus(j, 10)"
                    @keydown.left.exact="onFocus(j, 8)"
                  />
                </td>
                <td class="table-button" style="text-align: center;">
                  <template class="table_col_input_action">
                    <v-icon
                      @click="rowIns(j)"
                      color="success"
                      :disabled="authority === '1'"
                    >
                      mdi-plus
                    </v-icon>
                    <v-icon
                      @click="rowDel(j)"
                      color="error"
                      :disabled="authority === '1'"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </v-card>
</template>

<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";

const baseRow = {
  denpyoNo: null,
  santiId: null,
  shohinIds: "",
  shohinId: null,
  edano: null,
  shohinNm: "",
  kikakuId: null,
  kikakuNm: "",
  irisuu: "",
  hattyusuu: "",
  taniId: null,
  taniNm: "",
  jissuu: "",
  tanka: "",
  bikou: "",
  tantoId: null,
  tantoNm: ""
};

const headers = [
  "産地",
  "品名",
  "規格",
  "入数",
  "発注数",
  "単位",
  "実数",
  "単価",
  "備考",
  "担当者"
];

export default {
  name: "JyutyuDataTable",
  mixins: [Common, Forms, ShowDialogs, Api],
  props: {
    tableData: Array
  },
  data() {
    return {
      headers: headers,
      errorCount: 0
    };
  },
  computed: {
    localtable: {
      get: function() {
        return this.tableData;
      },
      set: function(tableData) {
        this.$emit("setdata", tableData);
      }
    },
    authority: {
      get() {
        return this.$store.state.authority;
      }
    }
  },
  methods: {
    async rowDelConfirm() {
      return await this.$deleteConfirm(
        "現在選択中の行を削除します。\nよろしいですか？"
      );
    },
    rowIns(index) {
      console.log("rowIns", "開始");
      const base = Object.assign({}, baseRow);
      const tanto = this.selectTanto;
      const tableData = this.tableData;
      //テーブルに明細番号を挿入する
      //担当者が選択されているとき
      if (tanto) base.tantoId = tanto;
      this.localtable = [
        ...tableData.slice(0, index),
        ...[base],
        ...tableData.slice(index)
      ];
    },
    async rowDel(index) {
      console.log("rowDel", "開始");
      if ((await this.rowDelConfirm()) != true) return;
      this.tableData.splice(index, 1);
    },
    onFocus(tr, td) {
      const ele = this.findSabledElement(tr, td);
      setTimeout(() => ele?.focus(), 150);
    },
    findSabledElement(trIndex, tdIndex) {
      const tbody = this.$refs.tbody;
      const trList = [...tbody.rows];
      const tr = trList.find(e => e.rowIndex === trIndex + 1);
      if (tr) {
        const tdList = [...tr.cells];
        const td = tdList.find(e => e.cellIndex === tdIndex);
        if (td) {
          const input = td.querySelector("input");
          if (input && !input.disabled) {
            return input;
          } else {
            return this.findSabledElement(trIndex, tdIndex + 1);
          }
        } else {
          if (tdIndex == 99) {
            return this.findSabledElement(trIndex - 1, 9);
          } else {
            return this.findSabledElement(trIndex + 1, 0);
          }
        }
      }
      return null;
    }
  },
  watch: {
    tableData: {
      handler() {
        this.$nextTick(() => {
          const refs = this.$refs.inputError;
          if (Array.isArray(refs)) {
            if (refs.length === 0) {
              this.$emit("errorCount", 0);
              return;
            }
            const errorRefs = refs.filter(e =>
              e.classList.contains("inputError")
            );
            this.errorCount = errorRefs.length;
            this.$emit("errorCount", this.errorCount);
          }
        });
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style lang="scss" scoped>
.inputError {
  background-color: rgba(255, 0, 0, 0.25);
}
#search_panel ::v-deep .v-select__slot {
  max-height: 50px;
}
#search_panel ::v-deep .v-expansion-panel-content__wrap {
  padding-bottom: 0;
}
#filter_panel ::v-deep button.v-expansion-panel-header {
  padding: 0 24px;
  max-height: 10px !important;
}
#meisai_card ::v-deep .v-input__slot {
  padding-right: 0 !important;
  min-height: 50px;
}
tbody tr td {
  vertical-align: middle;
  padding: 2px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.38);
}
#tokui_radio_group ::v-deep .v-input--radio-group {
  margin-top: 0;
}
.table_sticky thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #ffed00;
  border-top: #ffffff;
  background-clip: content-box !important;
  text-align: center !important;
  font-size: 16px !important;
  height: 25px !important;
  padding: 4px !important;
}
.table_col_input_number {
  border-spacing: 1px;
  border-collapse: collapse;
  border-style: solid;
  border-width: 1px;
  text-align: right;
  height: 50px;
  width: 100%;
}
.table_col_input_text {
  border-spacing: 1px;
  border-collapse: collapse;
  border-style: solid;
  border-width: 1px;
  height: 50px;
  width: 100%;
  padding: 0 3px;
}
.td-santi,
.td-kikaku,
.td-tanni,
.td-tanto,
.td-text {
  width: 10%;
}
.td-shohin {
  width: 18%;
}
.td-number {
  width: 7%;
}
.v-data-table__wrapper {
  overflow-y: scroll;
  height: 75vh;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  padding-left: 4px;
  padding-right: 4px;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  color: rgba(0, 0, 0, 0.6);
}
/* タブレット縦 */
@media screen and (max-width: 1024px) {
  #meisai_card {
    width: 1000px;
    overflow-x: scroll;
  }
  .table_col_input_santi,
  .table_col_input_kikaku,
  .table_col_input_tanni,
  .table_col_input_tanto,
  .table_col_input_text {
    min-width: 100px;
  }
  .table_col_input_shohin {
    min-width: 200px;
  }
  .table_col_input_number {
    min-width: 50px;
  }
}
/* スマホ横 */
@media screen and (max-width: 896px) {
  #meisai_card {
    width: 850px;
    overflow-x: scroll;
  }
  .table_col_input_santi,
  .table_col_input_kikaku,
  .table_col_input_tanni,
  .table_col_input_tanto,
  .table_col_input_text {
    min-width: 100px;
  }
  .table_col_input_shohin {
    min-width: 200px;
  }
  .table_col_input_number {
    min-width: 50px;
  }
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
  #meisai_card {
    width: 450px;
    overflow-x: scroll;
  }
  .table_col_input_santi,
  .table_col_input_kikaku,
  .table_col_input_tanni,
  .table_col_input_tanto,
  .table_col_input_text {
    min-width: 100px;
  }
  .table_col_input_shohin {
    min-width: 200px;
  }
  .table_col_input_number {
    min-width: 50px;
  }
}
/* 印刷用画面 */
@page {
  size: A4 landscope;
  margin: 4% 7% 4% 5%;
}
</style>
<style lang="scss">
@media print {
  .page > :not(#meisai_card) {
    display: none !important;
  }
  .table-button {
    display: none !important;
  }
  nav {
    display: none !important;
  }
  header {
    display: none !important;
  }
  main {
    padding: 0 !important;
  }
  .container {
    max-width: 100% !important;
    padding: 0 !important;
  }
  .v-data-table {
    height: auto !important;
    width: auto !important;
    overflow: hidden !important;
  }
}
</style>
